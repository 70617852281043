@tailwind base;
@tailwind components;
@tailwind utilities;

body {
background-color:#f1f2f6 ;
margin-bottom: 0;
padding-bottom: 0%;
}


::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }